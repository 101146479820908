<template>
  <v-container fluid>
    <div class="text-h5 font-weight-bold mx-10 my-3 indigo--text">
      Trainer Account Creation Sucessful
    </div>
    <v-card width="600px" flat>
      <v-row>
        <div class="text-h6 font-weight-bold mx-10 mt-2">
          Trainer name :
          <span class="mx-2">{{ firstName }} {{ lastName }}</span>
        </div>
      </v-row>
      <v-row>
        <div class="text-h6 font-weight-bold mx-10 mt-1">
          Trainer ID : <span class="mx-3">{{ trainerId }}</span>
        </div>
      </v-row>
      <v-row>
        <div class="text-h6 font-weight-bold mx-10 mt-1">
          Ot owner:
        </div>
       <v-col cols="4" >
         <v-select :items="otOwners" label="" class="mb-n1"></v-select>
         <span class="red--text" v-if="noOtSelected">select an ot owner</span>
         </v-col> 
      </v-row>
      <v-btn outlined class="mx-10">Go to trainers profile</v-btn>
    </v-card>
  </v-container>
</template>

<script>
//import Title from "../../../components/SingleTitle/singleTitle.vue";
// import { mapState } from 'vuex'
export default {
  name: "TrainerAccuntCreation",
  components: {
  //  Title
  },
  data() {
    return {
      firstName: "David",
      lastName: "Raj",
      trainerId: "#OT0000567",
      otOwners: ["David", "Kishore", "Brij"],
      noOtSelected:false
    };
  }
};
</script>

<style></style>
